<template>
  <img
    :src="iconName"
    :style="styling"
    :class="'svg-' + color"
    alt=""
  >
</template>

<script>
export default {
    name: "IconComponent",
    props: ["width", "height", "color"],
    computed: {
        styling: function () {
            return {
                minHeight: this.height + "px",
                minWidth: this.width + "px",
                height: this.height + "px",
                width: this.width + "px",
            };
        },
        iconName: function () {
            return "/icons/" + this.$slots.default[0].text.trim() + ".svg";
        }
    },
};
</script>
