<template>
  <SModal>
    <form
      ref="modal_window"
      class="d-flex d-col space-between"
      @submit.prevent="validate"
    >
      <div class="d-flex space-between align-center">
        <div class="font-18">
          {{ $t('modal.account.account') }}
        </div>
        <s-icon
          color="grey"
          @click.native="closeModal"
        >
          close
        </s-icon>
      </div>
      <div class="center mt-10 missingText red--text font-14">
        {{ message }}
      </div>
      <form
        ref="formAvatar"
        class="none"
      >
        <input
          ref="fileAvatar"
          type="file"
          name="avatar"
          @change="upload"
        />
      </form>
      <section class="center">
        <div class="relative">
          <div class="profile">
            <img
              v-if="avatar"
              class="pic"
              :src="avatarUrl"
              alt=""
            />
            <s-icon
              v-if="!avatar"
              height="100"
            >
              account
            </s-icon>
            <pulse-loader
              :loading="load"
              color="white"
              size="10px"
              class="absolute"
            />
          </div>
          <button
            type="button"
            class="white round camera center opacity-60"
            @click="openPicker"
          >
            <s-icon class=""> camera </s-icon>
          </button>
        </div>
      </section>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">
          {{ $t('modal.account.email') }}
        </div>
        <s-text-field
          v-model="email"
          disabled
          height="30"
        />
        <div class="font-14 opacity-60">
          {{ $t('modal.account.first_name') }}
        </div>
        <s-text-field
          v-model="firstName_input"
          height="30"
        />
        <div class="font-14 opacity-60">
          {{ $t('modal.account.last_name') }}
        </div>
        <s-text-field
          v-model="lastName_input"
          height="30"
        />
        <div class="font-14 opacity-60">
          {{ $t('modal.account.timezone') }}
        </div>
        <s-timezone v-model="timezone" />

        <div class="font-14 opacity-60">
          {{ $t('modal.account.current_password') }}
        </div>
        <s-text-field
          v-model="password"
          type="password"
          password
          height="30"
        />
        <div class="font-14 opacity-60">
          {{ $t('modal.account.new_password') }}
        </div>
        <div>
          <s-text-field
            v-model="newPassword"
            bar
            password
            height="30"
            type="password"
          />
        </div>
        <div class="font-14 opacity-60">
          {{ $t('modal.account.confirm_password') }}
        </div>
        <s-text-field
          v-model="confirmPassword"
          type="password"
          password
          height="30"
        />

        <div class="font-14 opacity-60">
          {{ $t('modal.account.region') }}
        </div>
        <s-dropdown
          v-model="preferredRegion"
          :height="30"
          class="w100 item"
          :items="servers"
        />

        <div class="font-14 opacity-60">
          {{ $t('modal.account.enable_tooltip') }}
        </div>
        <s-checkbox
          v-model="check"
          @click.native="check = !check"
        />
      </div>

      <div class="d-flex justify-end mt-40">
        <s-btn
          type="button"
          height="36"
          class="mr-10"
          @click.native="closeModal"
        >
          {{ $t('modal.cancel') }}
        </s-btn>
        <s-btn
          type="submit"
          height="36"
          class="green"
        >
          {{ $t('modal.save') }}
        </s-btn>
      </div>
    </form>
  </SModal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AccountComp',
  data() {
    return {
      firstName_input: '',
      lastName_input: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      message: '',
      timezone: '',
      check: true,
      preferredRegion: '',
      load: false,
    };
  },
  methods: {
    async post() {
      let data = {
        firstName: this.firstName_input,
        lastName: this.lastName_input,
        timezone: this.timezone,
        tooltips: this.check === true ? 'yes' : 'no',
        preferredRegion: this.preferredRegion.value,
      };

      if (this.password.length) {
        data.oldPassword = this.password;
        data.newPassword1 = this.newPassword;
        data.newPassword2 = this.confirmPassword;
      }
      this.$store.commit('user/tip', this.check);
      let response = await this.$store.dispatch('user/setProfile', data);
      if (response.status === 'ok') return this.closeModal();
      let message = this.$t('server.' + response.errorCode);
      if (response.status === 'error') this.message = message;
    },
    closeModal() {
      this.$store.commit('modals/accountModal', false);
    },
    validateName() {
      let exr = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;
      let first = this.firstName_input.match(exr);
      let last = this.lastName_input.match(exr);
      let code = '';
      if (!last) code = 'invalid_lastname';
      if (!first) code = 'invalid_firstname';
      if (this.lastName_input.length < 2) code = 'min_last_name';
      if (this.firstName_input.length < 2) code = 'min_first_name';
      if (this.lastName_input.length === 0) code = 'enter_last_name';
      if (this.firstName_input.length === 0) code = 'enter_first_name';
      if (!code) return true;
      this.message = this.$t('server.' + code);
    },
    validate() {
      this.message = '';
      if (!this.validateName()) return;
      if (this.password.length > 0) {
        if (this.newPassword !== this.confirmPassword) {
          this.message = this.$t('modal.account.password_no_match');
          return;
        }
        if (this.newPassword.length < 8) {
          this.message = this.$t('modal.account.password_min_8_char');
          return;
        }
      }
      this.post();
    },
    openPicker() {
      this.$refs.fileAvatar.click();
    },
    async upload(data) {
      let formData = new FormData(this.$refs.formAvatar);
      this.load = true;
      await this.$store.dispatch('user/setProfilePicture', formData);
      await this.$store.dispatch('user/getUserInformation');
      this.load = false;
    },
  },
  computed: {
    avatarUrl() {
      return process.env.VUE_APP_URL + '/api/avatar/' + this.avatar;
    },
    servers() {
      let data = [
        { display: this.$t('languages.na'), value: 'na' },
        { display: this.$t('languages.eu'), value: 'eu' },
      ];
      let ob = data.findIndex((el) => {
        return el.value == this.preferredRegion.value;
      });
      if (ob === -1) {
        ob = data.findIndex((el) => el.value == 'na');
      }
      console.log('IN HERE', data[ob]);
      data[ob].selected = true;

      return data;
    },
    ...mapState('user', [
      'email',
      'firstName',
      'lastName',
      'preferredRegion',
      'avatar',
      'tip',
    ]),
  },
  mounted() {
    this.firstName_input = this.firstName;
    this.lastName_input = this.lastName;
    this.check = this.tip;
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.profile {
  background-color: grey;
  height: 100px;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.camera {
  position: absolute;
  right: 0;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}

.red {
  background-color: #df5151;
}

.orange {
  background-color: #df9851;
}

.green {
  background-color: #55af6d;
}

.blue {
  background-color: #517cdf;
}

@media only screen and (max-width: 576px) {
  .account_modal {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>
