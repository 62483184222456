<template>
  <div
    ref="timePicker"
    v-hotkey="{ esc: close }"
    class="timePicker"
  >
    <div class="d-flex w100">
      <div
        type="button"
        class="w100"
      >
        <input
          ref="button"
          v-model="userTyped"
          type="text"
          placeholder="Search..."
          :style="inputStyling"
          data-lpignore="true"
          @keydown.prevent.down="down"
          @keydown.prevent.up="up"
          @keydown.prevent.enter="toggle"
          @keydown="keydown($event)"
          @click="toggle"
        >
        <div
          ref="line"
          class="line"
        />
      </div>
      <div
        class="box center"
        :class="{ selected: show }"
        :style="boxStyling"
        @click="toggle"
      >
        <s-icon
          v-show="clear"
          class="pa-5"
          height="20"
          color="grey"
        >
          chevron-down
        </s-icon>
        <s-icon
          v-show="!clear"
          height="16"
          color="grey"
          class="pa-5"
          @click.native="clearInput"
        >
          close
        </s-icon>
      </div>
    </div>
    <transition name="select">
      <div
        v-if="show"
        class="dropdown"
      >
        <div
          v-for="(item, idx) in filteredList"
          :key="idx"
          :ref="item == selected"
          :style="element"
          class="item space-between pointer"
          :class="{ selected: index == idx, selected1: index == idx }"
          @click="select(item)"
        >
          <div>{{ item }}</div>
          <s-icon
            v-if="item == selected"
            class="mr-10"
            height="16"
            color="white"
          >
            check
          </s-icon>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {mapState} from "vuex";

export default {
    name: "TimezoneComponent",
    props: ["height", "prefill"],
    data() {
        return {
            selected: "",
            show: false,
            options: [],
            minutes: "",
            hours: "",
            userTyped: "",
            index: 0,
            filteredList: [],
            enablePrefill: false,
        };
    },
    methods: {
        onFocus() {
            this.userTyped = "";
            this.$refs.line.style.width = "100%";
            this.$refs.button.focus();
            this.show = true;
        },
        listen(event) {
            const el = this.$refs.timePicker;
            if (!el) {
                return;
            }

            if (!el.contains(event.target)) {
                this.close();
            }
        },
        showPicker() {
            this.show = true;
        },
        clearInput() {
            this.userTyped = "";
        },
        async keydown(e) {
            let ignore = [9, 13, 40, 37, 38, 39];
            if (e.keyCode === 9) {
                return (this.show = false);
            }

            if (ignore.includes(e.keyCode)) {
                return;
            }

            await this.$wait();
            this.show = true;
            this.index = 0;
        },
        down() {
            let idx = this.index;
            if (idx + 1 >= this.filteredList.length) {
                return (this.index = 0);
            }

            this.index = idx + 1;

            const element = document.getElementsByClassName("selected1")[0];
            if (!element) {
                return;
            }
            element.scrollIntoView({inline: "nearest"});
        },
        toggle() {
            this.$emit("focused");
            if (this.show) {
                const secondKey = this.filteredList[this.index];
                this.select(secondKey);
                this.close();
            } else {
                this.onFocus();
                this.show = true;
            }
        },
        up() {
            let top = this.filteredList.length - 1;
            if (this.index <= 0) {
                return (this.index = top);
            }
            this.index = this.index - 1;

            const element = document.getElementsByClassName("selected1")[0];
            if (!element) {
                return;
            }

            element.scrollIntoView({block: "end", inline: "nearest"});
        },
        select(val) {
            if (!val) {
                return;
            }

            setTimeout(() => {
                this.selected = val;
                this.userTyped = val;
                this.show = false;
                this.$refs.line.style.width = "0";
                this.$emit("input", val);
                this.$emit("blur");
            });
        },
        close() {
            if (this.show) {
                setTimeout(() => {
                    if (this.show) {
                        this.show = false;
                        this.userTyped = this.selected;
                        this.$refs.line.style.width = "0";
                    }
                });
            }
        },
    },
    computed: {
        clear() {
            return !(this.userTyped.length > 0 && this.show);
        },
        inputStyling: function () {
            let ob = {};
            if (this.height) {
                ob.height = this.height + "px";
            }

            return ob;
        },
        boxStyling: function () {
            let ob = {};
            if (this.height) {
                ob.height = this.height + "px";
                ob.minWidth = this.height + "px";
            }

            return ob;
        },
        element: function () {
            let ob = {};
            if (this.height) {
                ob.height = this.height + "px";
            }

            return ob;
        },
        ...mapState("user", ["timezones", "userTimezone"]),
    },
    watch: {
        async prefill() {
            if (!this.enablePrefill || !this.prefill) {
                return;
            }

            this.selected = this.prefill;
            this.userTyped = this.prefill;
            this.index = this.timezones.indexOf(this.prefill);
        },
        async userTyped() {
            let typed = this.userTyped.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
            this.filteredList = this.timezones.filter((val) => {
                return val.toLowerCase().search(typed.toLowerCase()) !== -1;
            });
            await this.$wait();

            const el = this.$refs.true;
            let config = {block: "nearest", inline: "start"};
            if (el && el.length > 0) {
                el[0].scrollIntoView(config);
            }
        },
    },
    destroyed() {
        document.body.removeEventListener("click", this.listen);
    },
    async mounted() {
        this.selected = this.userTimezone;
        this.userTyped = this.userTimezone;
        this.index = this.timezones.indexOf(this.userTimezone);

        if (this.prefill) {
            this.selected = this.prefill;
            this.userTyped = this.prefill;
            this.index = this.timezones.indexOf(this.prefill);
        }

        this.$emit("input", this.userTimezone);
        await this.$wait();

        document.body.addEventListener("click", this.listen);
        await this.$wait(200);

        this.enablePrefill = true;
    },
};
</script>

<style scoped>
.timePicker {
  width: 100%;
  position: relative;
}

.custom {
  width: 138px;
  height: 130px;
  background-color: #1a2430;
  font-family: Roboto;

  color: #a6acb1;
  padding: 9px 10px;
  box-sizing: border-box;
}

.custom_title {
  font-size: 10px;
  font-style: italic;
}

.custom_input {
  margin-top: 9px;
  width: 50px;
  height: 25px;
  background-color: #303b49;
}

.custom_input:hover {
  background-color: #303b49c7;
}

.custom_input:focus {
  background-color: #303b49c7;
}

.custom_input_label {
  margin-left: 10px;
  font-size: 14px;
}

.box {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  background-color: #151b23;
  margin-left: 2px;
}

.dropdown {
  position: absolute;
  background-color: #151b23;
  z-index: 1;
  font-size: 14px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  width: 100%;
  overflow: auto;
  overflow: overlay;
  max-height: 250px;
}

.dropdown > div {
  height: 30px;
  width: 133px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  color: rgb(160, 160, 160);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.dropdown > div:hover {
  background-color: #000000;
  color: rgb(255, 255, 255);
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.selected_timezone {
  background-color: #000000;
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  outline: none;
}

.line {
  width: 0;
  height: 1px;
  background-color: #89ccff;
  transition: all ease 0.2s;
}

.selected {
  background-color: #000000;
}

@media only screen and (max-width: 576px) {
  .dropdown {
    top: 42px;
  }
}
</style>
