<template>
  <div class="logo">
    <img
      src="/images/logo-large.svg"
      alt="PEER2PEER Technologies"
    >
  </div>
</template>

<script>
export default {
    name: "LogoComp",
};
</script>

<style scoped>
.logo * {
  box-sizing: border-box;
}

.logo {
  position: relative;
}

@media only screen and (max-width: 576px) {
  .logo {
    display: none;
  }
}
</style>
