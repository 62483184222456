<template>
  <FocusLoop>
    <div
      ref="modal_page"
      class="modal_page center"
    >
      <form
        ref="modal_window"
        class="modal_window d-flex center d-col space-between"
        @submit.prevent="post"
      >
        <div class="d-flex space-between align-center w100">
          <div class="font-18 w100 text-center">
            {{ $t("modal.firstTime.title") }}
          </div>
          <s-icon
            color="grey"
            @click.native="closeModal"
          >
            close
          </s-icon>
        </div>
        <hr class="my-20 w100">
        <div class="center d-col w100">
          <div class="mb-20">
            {{ $t("modal.firstTime.one") }}
          </div>
          <s-timezone
            v-model="timezone"
            :prefill="timezone"
            height="40"
            class="mb-10"
          />
          <div class="text-grey font-12 italic">
            {{ $t("modal.firstTime.example") }}
          </div>
          <div class="mt-40 mb-20">
            {{ $t("modal.firstTime.two") }}
          </div>
          <div class="d-flex w100 relative">
            <div
              class="color_box"
              :style="{ backgroundColor: 'var(--sm-color-' + colorID + ')' }"
              @click="togglePicker"
            >
              <s-color-picker
                v-if="showPicker"
                v-model="colorID"
              />
            </div>
            <div
              class="box center mr-10"
              @click="togglePicker"
            >
              <s-icon
                class="pa-5"
                height="16"
                color="grey"
              >
                chevron-down
              </s-icon>
            </div>

            <s-text-field
              v-model="roomName"
              block
              :error="error"
              placeholder="Meeting Room Name…"
            />
          </div>
        </div>
        <div class="mt-40 button_wrapper">
          <s-btn
            block
            type="submit"
            height="36"
            class="defaultButton"
          >
            {{
              $t("modal.firstTime.save")
            }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import {mapState} from "vuex";
import {gsap} from "gsap";

export default {
    data() {
        return {
            showPicker: false,
            roomName: "",
            colorID: 1,
            timezone: "",
            error: ''
        };
    },
    methods: {
        async post() {
            this.$store.dispatch("user/setProfile", {
                timezone: this.timezone,
            });
            const rooms = await this.$store.dispatch("rooms/getRoomsInformation");

            this.$store.commit("rooms/editRoom", rooms[0]);

            if (this.roomName) {
                this.$store.commit("rooms/setEditRoomName", this.roomName);
            }

            this.$store.commit("rooms/editColor", this.colorID);

            let res = await this.$store.dispatch("rooms/updateRoom");
            this.error = this.$t('server.' + res.errorCode);
            if (res.status === "ok") {
                this.closeModal();
            }
        },
        togglePicker() {
            this.showPicker = !this.showPicker;
        },
        openPicker() {
            this.showPicker = true;
        },
        closerPicker() {
            this.showPicker = false;
        },
        closeModal() {
            gsap.to(this.$refs.modal_page, {
                opacity: 0,
                duration: 0.3,
                ease: "power2.out",
            });
            gsap.to(this.$refs.modal_window, {
                scale: 0.7,
                duration: 0.3,
                ease: "power2.out",
                onComplete: () =>
                    this.$store.commit("modals/firstTimeWizardModal", false),
            });
        },
    },
    computed: {
        ...mapState("rooms", ["rooms"]),
        ...mapState("user", ["location", "timezones"]),
    },
    beforeMount() {
        this.timezone = this.location.timezone;
        if (this.timezone === 'UTC' || this.timezone == null) {
            this.timezone = 'Europe/London';
        }
    },
    mounted() {
    //this.timezone = 'Europe/London'
        if (this.rooms) {
            this.colorID = this.rooms[0].color;
        }

        gsap.from(this.$refs.modal_page, {
            opacity: 0,
            duration: 0.3,
            ease: "power2.out",
        });

        gsap.from(this.$refs.modal_window, {
            scale: 0.7,
            duration: 0.3,
            ease: "power2.out",
        });
    },
};
</script>

<style scoped>
.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.color_box {
  min-width: 40px;
  height: 40px;
  border: 8px solid #17232e;
  box-sizing: border-box;
}

.modal_page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.button_wrapper {
  width: 258px;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>
