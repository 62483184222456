import router from '../router/index';
import store from '../store/index';

export const auth = (response) => {
  if (!response) {
    return true;
  }

  if (router.currentRoute.name === 'Login') {
    return true;
  }

  if (response.errorCode !== 'authentication_error') {
    return true;
  }

  if (!store.state.user.email) {
    return true;
  }

  store.commit('user/clear');
  router.push('/login');

  return false;
};

const header = { 'Content-type': 'application/json; charset=UTF-8' };

export const get = async (url) => {
  url = process.env.VUE_APP_URL + url;

  let response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: header,
  });

  response = await response.json();

  auth(response);

  return response;
};

export const post = async (url, data) => {
  url = process.env.VUE_APP_URL + url;

  let response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    headers: header,
  });

  response = await response.json();

  auth(response);

  return response;
};

export const del = async (url) => {
  url = process.env.VUE_APP_URL + url;

  let response = await fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: header,
  });

  response = await response.json();

  auth(response);

  return response;
};

export const validateEmail = (email) => {
  return /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
};
export const validatePhoneNumber = (phoneNumber) => {
  return /^[+|0{2}]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/.test(phoneNumber);
};
