<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <ModalController />
  </div>
</template>

<script>
import {mapState} from "vuex";
import ModalController from "@/components/Modals/Assets/Controller.vue";

export default {
    components: {
        ModalController,
    },
    methods: {
        initUserAccount() {
            this.$store.commit("calendar/loadData");
            this.$store.dispatch("user/getUserInformation");
            this.$store.dispatch("user/getConfiguration");
            this.$store.dispatch("calendar/innitGetUpcomingEvents");
        },
        initGuestAccount() {
            this.$store.dispatch("user/getLangGuest");
        },
        initCloudOrOnPremise() {
            const onpremise = process.env.VUE_APP_BUILD_TYPE !== "cloud";
            this.$store.commit("user/onpremise", onpremise);
        },
    },
    computed: {
        ...mapState("user", ["email"]),
    },
    mounted() {
        this.initCloudOrOnPremise();

        if (this.email) {
            this.initUserAccount();
        } else {
            this.initGuestAccount();
        }

        this.$store.dispatch("user/getLocation");
        this.$store.dispatch("user/getConfiguration");
    },
};
</script>

