<template>
  <FocusLoop>
    <div class="modal_page center">
      <form
        class="modal_window d-flex d-col space-between"
        @submit.prevent="post"
      >
        <div class="d-flex space-between align-center">
          <div class="font-18">
            {{ $t("modal.saveEvent.title") }}
          </div>
          <s-icon
            color="grey"
            @click.native="closeModal"
          >
            close
          </s-icon>
        </div>
        <div class="">
          <s-radio
            v-model="radio"
            class="py-30"
            vertical
            :options="options"
          />

          <label
            for=""
            class="font-14 opacity-60"
          >{{
            $t("modal.saveEvent.email")
          }}</label>
          <s-text-area
            :min-height="110"
            :placeholder="$t('modal.saveEvent.placeholder')"
            class="pt-5"
          />
          <span class="font-12 opacity-60">{{
            $t("modal.saveEvent.meeting")
          }}</span>
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn
            type="button"
            height="36"
            class="mr-10"
            @click.native="closeModal"
          >
            {{ $t("modal.close") }}
          </s-btn>
          <s-btn
            type="submit"
            height="36"
            class="defaultButton"
          >
            {{ $t("modal.save") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import {gsap} from "gsap";

export default {
    data() {
        return {
            options: [
                this.$t("modal.saveEvent.this_meeting"),
                this.$t("modal.saveEvent.following_meetings"),
            ],
            radio: 0,
        };
    },
    methods: {
        async post() {
            if (this.radio === 0) {
                await this.$store.dispatch("schedule/updateEvent");
            } else {
                await this.$store.dispatch("schedule/updateEvent", {allEvents: true});
            }

            this.$store.commit("schedule/resetState");
            this.closeModal();
        },
        closeModal() {
            gsap.to(".modal_page", {
                opacity: 0,
                duration: 0.3,
                ease: "power2.out",
            });
            gsap.to(".modal_window", {
                scale: 0.7,
                duration: 0.3,
                ease: "power2.out",
                onComplete: () =>
                    this.$store.commit("modals/saveEventConfirmation", false),
            });
        },
    },
    computed: {},
    mounted() {
        gsap.from(".modal_page", {
            opacity: 0,
            duration: 0.3,
            ease: "power2.out",
        });

        gsap.from(".modal_window", {
            scale: 0.7,
            duration: 0.3,
            ease: "power2.out",
        });
    },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>
