<template>
  <s-modal>
    <form
      class="col gap-50"
      @submit.prevent="post"
    >
      <section class="header row space-between align-center">
        <span class="font-18">
          {{ action }} {{ $t("modal.addGroup.title") }}
        </span>
        <button
          type="button"
          class="center"
          @click="close"
        >
          <s-icon color="grey">
            close
          </s-icon>
        </button>
      </section>
      <section class="body col gap-20">
        <div
          class="center text-red missingText font-14"
          :class="{ 'base-text': message.status == 'ok' }"
        >
          {{ message }}
        </div>
        <div class="grid align-center">
          <div class="font-14 opacity-60">
            {{ $t("modal.addGroup.name") }}
          </div>
          <s-text-field
            ref="name"
            v-model="name"
            autofocus
          />
          <div class="font-14 opacity-60">
            {{ $t("modal.addGroup.attendees") }}
          </div>
          <s-dropdown
            :height="40"
            :items="participants"
            multi
            @multiSelect="syncEmails"
          />
        </div>
        <div class="opacity-60 text-center">
          {{ $t("modal.addGroup.color") }}
        </div>
        <div class="w100 d-flex wrap center">
          <button
            v-for="item in 20"
            :key="item"
            type="button"
            class="color"
            :class="{ active: item == color }"
            :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }"
            @click="selectColor(item)"
          />
        </div>
        <div class="wrapper gap-10 col">
          <div
            v-if="emails.length == 0"
            class="items center"
          >
            {{ $t("modal.addGroup.no_attendee") }}
          </div>
          <div
            v-for="(item, index) in emails"
            :key="index"
            class="col items"
          >
            <div class="col gap-1">
              <span> {{ index + 1 }}. {{ item.display }}</span>
              <span class="font-14 opacity-75 pl-15">
                {{ getAttendeeByEmail(item.display).name }}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section class="footer row gap-20 justify-end">
        <s-btn
          height="36"
          type="button"
          @click.native="close"
        >
          {{ $t("modal.cancel") }}
        </s-btn>
        <s-btn
          height="36"
          class="green"
          :loading="loading"
        >
          {{ $t("modal.save") }}
        </s-btn>
      </section>
    </form>
  </s-modal>
</template>

<script>
import {mapState} from "vuex";

export default {
    data() {
        return {
            name: "",
            color: 1,
            participants: [],
            emails2: [],
            emails: [],
            loading: false,
            message: "",
            id: "",
        };
    },
    methods: {
        async post() {
            const ids = this.emails.map((a) => a.value);

            let data = {
                name: this.name,
                color: this.color,
                attendees: JSON.stringify(ids),
            };

            if (this.edit) {
                data.id = this.id;
            }

            const method = this.edit ? "updateGroupsByID" : "postGroup";
            this.loading = true;

            const res = await this.$store.dispatch(`attendees/${method}`, data);

            this.loading = false;
            this.$store.dispatch("attendees/getGroups");

            if (res?.status !== "error") {
                return this.close();
            }

            this.message = this.$t(`server.${res.errorCode}`);
        },
        close() {
            this.$store.commit("modals/addGroup", false);
        },
        selectColor(val) {
            this.color = val;
        },
        del(index) {
            const entry = this.emails[index].value;
            const participant = this.participants.find((el) => el.value === entry);
            this.emails.splice(index, 1);
            participant.selected = false;
        },
        syncEmails(val) {
            this.emails = val;
        },
        getAttendeesByEmail(val) {
            let res = [];
            for (let item of val) {
                res.push({display: item, value: item, selected: true});
            }
            return res;
        },
        getAttendeeByEmail(email) {
            return this.attendees.find((el) => el.email === email);
        },
        getAttendeesByID(emails) {
            let attendees = [];
            for (let email of emails) {
                const res = this.attendees.find((el) => el.email === email);
                const data = {display: res.email, value: res.id, selected: true};
                attendees.push(data);
            }
            return attendees;
        },
        populateDropdown() {
            this.participants = [];
            for (let x of this.attendees) {
                const res = this.emails.find((el) => el.display === x.email);
                const selected = !!res;
                const data = {display: x.email, value: x.id, selected};
                this.participants.push(data);
            }
        },
    },
    computed: {
        action() {
            return this.edit
                ? this.$t("modal.addGroup.edit")
                : this.$t("modal.addGroup.create");
        },
        ...mapState("attendees", ["attendees", "edit"]),
    },
    mounted() {
        if (this.edit) {
            this.name = this.edit.name;
            this.color = this.edit.color;
            this.emails = this.getAttendeesByID(this.edit.attendees);
            this.id = this.edit.id;
        }
        this.populateDropdown();
    },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.items {
  font-size: 14px;
  color: #74b7e4;
  justify-content: space-between;
  max-width: 460px;
}

.wrapper {
  height: 150px;
  overflow: auto;
  border: 1px solid hsla(156, 41%, 42%, 0.2);
  box-sizing: border-box;
  background-color: #17242f;
  padding: 10px;
}

.color {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--base-theme-color);
}

.active {
  box-shadow: 0 0 20px var(--base-theme-color);
  border: 1px solid rgb(255, 255, 255);
}
</style>
