export default {
    bind(el, binding, vNode) {
        const bubble = binding.modifiers.bubble;
        const dynamicID = vNode.data.attrs?.dynamicID;

        const handler = (e) => {
            let pressed = false;

            for (let x of e.composedPath()) {
                if (x.id === "app") {
                    break;
                }

                if (x.classList || dynamicID) {
                    let con = binding.arg || dynamicID;

                    if (x.classList.value.includes(con)) {
                        pressed = true;
                        break;
                    }
                }
            }

            if (bubble || (!el.contains(e.target) && el !== e.target && !pressed)) {
                binding.value(e);
            }
        };

        el.__vueClickOutside__ = handler;
        document.addEventListener("touchstart", handler);
        document.addEventListener("mousedown", handler);
    },
    unbind(el, binding) {
        document.removeEventListener("touchstart", el.__vueClickOutside__);
        document.removeEventListener("mousedown", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
    },
};
