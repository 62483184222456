<template>
  <transition
    name="fade"
    appear
    @before-leave="beforeLeave"
  >
    <div class="modal-page center overlay">
      <transition
        name="scale"
        appear
      >
        <section
          ref="el"
          class="modal-window"
        >
          <FocusLoop>
            <slot />
          </FocusLoop>
        </section>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
    methods: {
        beforeLeave() {
            this.$refs.el.classList.add("transitions");
            this.$refs.el.style.transform = "scale(0.7)";
        },
    },
};
</script>

<style scoped>
.modal-page {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.4);
  z-index: 8;
}

.modal-window {
  padding: 30px 60px;
  box-shadow: 0 4px 10px 0 rgba(61, 16, 16, 0.35);
  background-color: #243748;
  min-width: 450px;
  max-width: 450px;
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .modal-window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    min-width: auto;
    max-width: none;
  }
}
</style>
