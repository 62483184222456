<template>
  <button
    ref="button"
    class="button center uppercase relative px-15 b-box"
    :disabled="disabled || loading"
    :style="styling"
    :type="type"
  >
    <pulse-loader
      :loading="load"
      :color="'var(--base-theme-color)'"
      size="10px"
    />
    <slot v-if="!load" />
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: [
    'width',
    'height',
    'color',
    'opacity',
    'font',
    'block',
    'min',
    'disabled',
    'icon',
    'outlined',
    'type',
    'loading',
  ],
  computed: {
    load() {
      const con1 = this.loading === true;
      const con2 = this.loading !== '';
      const con3 = typeof this.loading !== 'undefined';

      return con1 && con2 && con3;
    },
    styling: function () {
      let ob = {
        height: this.height + 'px',
        minWidth: this.width + 'px',
        backgroundColor: this.color,
        opacity: this.opacity,
        fontSize: this.font + 'px',
      };

      if (
        this.block &&
        this.block !== '' &&
        typeof this.block !== 'undefined'
      ) {
        ob.width = 'auto';
      }

      if (
        !this.block &&
        this.block === '' &&
        typeof this.block !== 'undefined'
      ) {
        ob.width = '100%';
      }

      if (this.outlined === '') {
        ob.border = '1px solid black';
        ob.color = 'black';
        ob.backgroundColor = 'transparent';
      }

      if (this.min === '' || this.min === 'true') {
        ob.minWidth = 0;
      }

      return ob;
    },
  },
  methods: {
    onFocus() {
      this.$refs.button.focus();
    },
  },
};
</script>

<style lang="less">
.iconLayout {
  display: grid;
  grid-template-columns: 25px 1fr 10px;
  padding-left: 10px;
}

.slot {
  margin: 20px;
}

.button {
  min-width: 95px;
  height: 30px;
  background-color: #cccccc;
  color: #484848;
  font-family: Roboto;
  font-size: 16px;
  outline: none;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
}

// .button:focus {
//   outline: 3px solid #425464;
// }

.button:hover {
  background-color: #e6e6e6;
  color: #484848;
}

.button:disabled {
  min-width: 95px;
  height: 30px;
  background-color: #626262;
  color: #484848;
  font-family: Roboto;
  font-size: 16px;
  outline: none;
  pointer-events: none;
}

.button:disabled:hover {
  background-color: #425464;
}

.red {
  background-color: #ecacac;
  color: #484848;
}

.red:hover {
  background-color: #f0c7c7;
}

.red:focus {
  outline: 2px solid #f0c7c7;
}

.buttonHover:hover {
  background-color: #3d9be2;
}

.defaultButton {
  background-color: #89ccff;
  color: #000;
}

.defaultButton:hover {
  background-color: #acdbff;
  color: #000;
}
</style>
