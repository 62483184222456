import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import user from "./user";
import rooms from "./rooms";
import modals from "./modals";
import billing from "./billing";
import schedule from "./schedule";
import toolbars from "./toolbars";
import calendar from "./caldendar";
import attendees from "./attendees";
import notifications from "./notifications";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        rooms,
        modals,
        toolbars,
        calendar,
        schedule,
        attendees,
        notifications,
        billing,
        auth
    },
    plugins: [
        createPersistedState({
            paths: [
                "user",
                "rooms",
                "modals",
                "schedule",
                "attendees",
                "toolbars",
                "calendar",
                "notifications",
                "billing",
                "auth"
            ],
        }),
    ],
});
