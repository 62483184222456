<template>
  <div class="color_picker">
    <div class="font-14 pb-10 opacity-60">
      {{ $t("atoms.room_colors") }}
    </div>
    <div class="grid">
      <div
        v-for="item in 20"
        :key="item"
        class="item"
        :class="{ selected: item == selected, item_shaddow: item == selected }"
        :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }"
        @click="event(item)"
      />
    </div>
  </div>
</template>

<script>
export default {
    props: ["value"],
    data() {
        return {
            selected: 0,
        };
    },
    watch: {
        value() {
            this.selected = this.value;
        },
    },
    methods: {
        selection(color_id) {
            this.selected = color_id;
            this.$emit("input", color_id);
        },
        event(color_id) {
            this.$emit("input_Event", color_id);
            this.selection(color_id);
        },
    },
    mounted() {
        if (!this.value) {
            return this.selection(1);
        }

        this.selection(Number(this.value));
    },
};
</script>

<style lang="less" scoped>
.color_picker {
  position: absolute;
  width: 193px;
  height: 186px;
  bottom: -5px;
  left: 0;
  z-index: 1;
  transform: translateY(100%);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #141925;
  padding: 15px;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 25px 25px 25px 25px 25px;
  gap: 10px;
}

.selected {
  outline: 3px solid black;
  box-sizing: border-box;
  outline-offset: -3px;
}

.item {
  height: 25px;
  width: 25px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.item_shaddow {
  box-shadow: 0 0 0 2px rgba(243, 243, 243, 0.5);
  -webkit-box-shadow: 0 0 0 2px rgba(243, 243, 243, 0.5);
  -moz-box-shadow: 0 0 0 2px rgba(243, 243, 243, 0.5);
}

.item:hover {
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.753);
}
</style>
