import {get, post} from "../assets/functions";

export default {
    namespaced: true,
    actions: {
        async activate(context, data) {
            return await post("/invitation/activate");
        },
        async getEmail(context) {
            let url = "/invitation/activate/76c158ab5528d31fb2db9cd997779a43d1a32675";
            return await get(url);
        },
        async invitation(context, data) {
            return await post("/invitation", data);
        },
        async vip(context, data) {
            return await post("/vip", data);
        },
        async login(context, data) {
            return await post("/login", data);
        },
        /**
         * Sends an invitation to an event
         * @param context
         * @param data data object containing the required event id and emails
         * @param data.id event id to send the invite for
         * @param data.emails a list of emails to send the invites
         * @returns {Promise<*|Response>}
         */
        async invite(context, data) {
            const { id, emails} = data;
            return await post(`/api/invite/${id}/`, {emails});
        },
        async room(context, data) {
            return await get(data);
        },
        async register(context, data) {
            return await post("/register", data);
        },
    },
};
